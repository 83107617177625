import React, { useState, useEffect } from "react";
import styles from "../styles/portfolio.module.css";

const ProjectCard = ({ project }) => {
  return (
    <div className={styles.projectCard}>
      <div className={styles.projectImage}>
        <img src={project.thumbnail} alt={project.title} />
      </div>
      <div className={styles.projectContent}>
        <div className={styles.projectHeader}>
          <h3 className={styles.projectTitle}>{project.title}</h3>
          <div className={styles.categoryContainer}>
            {project.categories.map((category, index) => (
              <span key={index} className={styles.projectCategory}>
                {category}
              </span>
            ))}
          </div>
        </div>
        <p className={styles.projectDescription}>{project.description}</p>
        <div className={styles.visitLinkContainer}>
          <a
            href={project.liveLink}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.visitLink}
          >
            <svg
              className={styles.externalIcon}
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6" />
              <polyline points="15 3 21 3 21 9" />
              <line x1="10" y1="14" x2="21" y2="3" />
            </svg>
            Visit Site
          </a>
        </div>
      </div>
    </div>
  );
};

const Portfolio = ({ projects }) => {
  const [selectedCategory, setSelectedCategory] = useState("All");
  const allCategories = [
    "All",
    ...new Set(projects.flatMap((project) => project.categories)),
  ];
  const [filteredProjects, setFilteredProjects] = useState(projects);

  useEffect(() => {
    if (selectedCategory === "All") {
      setFilteredProjects(projects);
    } else {
      setFilteredProjects(
        projects.filter((project) =>
          project.categories.includes(selectedCategory)
        )
      );
    }
  }, [selectedCategory, projects]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.portfolioSection}>
      <div className={styles.container}>
        <div className={styles.portfolioHeader}>
          <h2 className={styles.portfolioTitle}>My Portfolio</h2>
          <p className={styles.portfolioDescription}>
            Throughout my career, I've had the privilege of collaborating with
            these exceptional companies. Each project represents unique
            challenges and solutions in web development, Salesforce
            implementations, and website design.
          </p>
        </div>

        <div className={styles.filterContainer}>
          {allCategories.map((category) => (
            <button
              key={category}
              onClick={() => setSelectedCategory(category)}
              className={`${styles.filterButton} ${
                selectedCategory === category ? styles.active : ""
              }`}
            >
              {category}
            </button>
          ))}
        </div>

        <div className={styles.projectsGrid}>
          {filteredProjects.map((project, index) => (
            <ProjectCard key={index} project={project} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
