import React from "react";
import {
  FaCheck,
  FaMobile,
  FaDesktop,
  FaDatabase,
  FaShieldAlt,
  FaCode,
  FaCogs,
  FaRocket,
} from "react-icons/fa";
import styles from "../styles/customAppsPage.module.css";
import ContactPage from "./contact";
import { useEffect } from "react";

const CustomAppsPage = () => {
  const handleSchedule = () => {
    window.open(
      "https://calendly.com/cooper-tintech/1-on-1-software-discovery-session-crafting-your-vision",
      "_blank"
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.customAppsPage}>
      <header className={styles.header}>
        <div className={styles.headerOverlay}></div>
        <div className={styles.headerContent}>
          <div className={styles.textContainer}>
            <h1>Custom Application Development</h1>
            <p>
              Building software solutions that solve your unique business
              challenges
            </p>
            <button onClick={handleSchedule} className={styles.primaryButton}>
              BOOK A FREE CONSULTATION
            </button>
          </div>
        </div>
      </header>

      <section className={styles.platforms}>
        <div className={styles.container}>
          <h2>We Build Custom Solutions</h2>
          <div className={styles.platformGrid}>
            <div className={styles.platformCard}>
              <FaDesktop className={styles.platformIcon} />
              <h3>Web Applications</h3>
              <p>
                Responsive, modern web applications that work seamlessly across
                all browsers and devices.
              </p>
            </div>
            <div className={styles.platformCard}>
              <FaMobile className={styles.platformIcon} />
              <h3>Mobile Solutions</h3>
              <p>
                Native and cross-platform mobile applications for iOS and
                Android devices.
              </p>
            </div>
            <div className={styles.platformCard}>
              <FaDatabase className={styles.platformIcon} />
              <h3>Backend Systems</h3>
              <p>
                Robust, scalable backend services and APIs that power your
                applications.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.development}>
        <div className={styles.container}>
          <h2>Our Development Process</h2>
          <div className={styles.processSteps}>
            <div className={styles.step}>
              <span className={styles.stepNumber}>1</span>
              <h3>Requirements Analysis</h3>
              <ul>
                <li>
                  <FaCheck /> Detailed project scoping
                </li>
                <li>
                  <FaCheck /> User story mapping
                </li>
                <li>
                  <FaCheck /> Gather tech requirements
                </li>
                <li>
                  <FaCheck /> Architecture planning
                </li>
              </ul>
            </div>
            <div className={styles.step}>
              <span className={styles.stepNumber}>2</span>
              <h3>Design & Development</h3>
              <ul>
                <li>
                  <FaCheck /> UI/UX design
                </li>
                <li>
                  <FaCheck /> Iterative development
                </li>
                <li>
                  <FaCheck /> Regular client reviews
                </li>
                <li>
                  <FaCheck /> Quality assurance
                </li>
              </ul>
            </div>
            <div className={styles.step}>
              <span className={styles.stepNumber}>3</span>
              <h3>Testing & Deployment</h3>
              <ul>
                <li>
                  <FaCheck /> Comprehensive testing
                </li>
                <li>
                  <FaCheck /> Performance optimization
                </li>
                <li>
                  <FaCheck /> Security auditing
                </li>
                <li>
                  <FaCheck /> Production deployment
                </li>
              </ul>
            </div>
            <div className={styles.step}>
              <span className={styles.stepNumber}>4</span>
              <h3>Support & Maintenance</h3>
              <ul>
                <li>
                  <FaCheck /> Ongoing monitoring
                </li>
                <li>
                  <FaCheck /> Regular updates
                </li>
                <li>
                  <FaCheck /> Performance tuning
                </li>
                <li>
                  <FaCheck /> Feature enhancements
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.features}>
        <div className={styles.container}>
          <h2>Core Features</h2>
          <div className={styles.featureGrid}>
            <div className={styles.featureCard}>
              <FaShieldAlt className={styles.featureIcon} />
              <h3>Security First</h3>
              <p>
                Built-in security features including encryption, secure
                authentication, and data protection.
              </p>
            </div>
            <div className={styles.featureCard}>
              <FaCode className={styles.featureIcon} />
              <h3>Clean Code</h3>
              <p>
                Maintainable, well-documented code following industry best
                practices and standards.
              </p>
            </div>
            <div className={styles.featureCard}>
              <FaCogs className={styles.featureIcon} />
              <h3>Scalable Architecture</h3>
              <p>
                Future-proof solutions designed to grow with your business
                needs.
              </p>
            </div>
            <div className={styles.featureCard}>
              <FaRocket className={styles.featureIcon} />
              <h3>Performance Optimized</h3>
              <p>
                Fast, efficient applications that provide excellent user
                experience.
              </p>
            </div>
          </div>
        </div>
      </section>

      <ContactPage id="contact" />
    </div>
  );
};

export default CustomAppsPage;
