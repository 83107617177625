import React from "react";
import { Link } from "react-router-dom";
import {
  FaLinkedin,
  FaInstagram,
  FaSalesforce,
  FaGithub,
} from "react-icons/fa";
import { BsFillBriefcaseFill } from "react-icons/bs";
import ContactPage from "./contact";
import { useEffect } from "react";

const skills = [
  "JavaScript / TypeScript",
  "React",
  "HTML / CSS",
  "C#",
  ".NET Core / ASP.Net",
  "Selenium",
  "Microsoft Azure",
  "AWS",
  "Python",
  "Salesforce Admin",
  "Salesforce Development",
  "Apex",
  "SQL",
  "Tech Consulting",
];

const TeamPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="team-page">
      <section id="about" className="about">
        <div className="container">
          <div className="profile-section">
            <div className="profile-image">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/tintech-solutions.appspot.com/o/IMG_4978.jpg?alt=media&token=5a3cb7a3-f5ec-48cd-9f92-73fa52c63b66"
                alt="Cooper Tingey"
              />
            </div>
            <div className="profile-content">
              <h3>About Me</h3>
              <p>
                My name is Cooper Tingey. I started writing software in 2020.
                Since then, I've earned my degree in Computer Science, worked
                for multiple companies, and expanded my skills and knowledge of
                the software industry. My first project was a small application
                that helped me start my own business in the neighborhood. Since
                then, I've built software for various businesses such as
                accounting firms, fitness coaches, Solar Consulting firms,
                software payment platforms, and created many of my own
                applications. I love helping businesses grow and scale.
              </p>
              <p>
                I got my Bachelor's degree in Computer Science - Business after
                taking a few courses on software development and falling in love
                with it. My education as a programmer continues every single
                day.
              </p>
              <a
                href="https://firebasestorage.googleapis.com/v0/b/tintech-solutions.appspot.com/o/Cooper%20Tingey%20Resume%202024.docx.pdf?alt=media&token=bac71bc0-0977-4838-bb0a-f89642512d04"
                download
                className="btn-primary"
              >
                Download Resume
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="personal">
        <div className="container">
          <div className="hobbies">
            <div className="hobby-content">
              <h3>Beyond Coding</h3>
              <p>
                Beyond coding, I enjoy playing sports and being outdoors. Golf,
                snowboarding, and basketball are some of my favorite activities.
                I also love hiking, fishing, exploring, and spending time with
                my wife. We got married in 2022 and have cherished every moment
                together.
              </p>
              <div className="social-buttons">
                <a
                  href="https://www.linkedin.com/in/cooper-tingey-5b501a1a6/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn-social btn-linkedin"
                >
                  <FaLinkedin size={24} /> LinkedIn
                </a>
                <a
                  href="https://www.instagram.com/coopting223/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn-social btn-instagram"
                >
                  <FaInstagram size={24} /> Instagram
                </a>
                <a
                  href="https://github.com/coopertingey123"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn-social btn-github"
                >
                  <FaGithub size={24} /> GitHub
                </a>
                <a
                  href="https://www.salesforce.com/trailblazer/zir2b84tcshbeq7wa4"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn-social btn-salesforce"
                >
                  <FaSalesforce size={24} /> Salesforce Trailhead
                </a>
                <Link to="/portfolio" className="btn-social btn-portfolio">
                  <BsFillBriefcaseFill size={24} /> Portfolio
                </Link>
              </div>
            </div>
            <div className="hobby-image">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/tintech-solutions.appspot.com/o/IMG_8591.jpg?alt=media&token=8318c113-078e-4987-9630-72bde9f751f4"
                alt="Cooper and Brynn"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="skills">
        <div className="container">
          <h3>Skills & Expertise</h3>
          <ul className="skills-list">
            {skills.map((skill, index) => (
              <li key={index}>{skill}</li>
            ))}
          </ul>
        </div>
      </section>
      <ContactPage id="contact" />
    </div>
  );
};

export default TeamPage;
