import React, { useState, useEffect } from "react";
import { IoMenu, IoClose } from "react-icons/io5";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 20);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <nav className={`navbar ${scrolled ? "scrolled" : ""}`}>
      <div className="navbar-container">
        <a href="/" className="navbar-logo">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/tintech-solutions.appspot.com/o/2.png?alt=media&token=72d59b20-e9a4-4841-8578-01e5623ade3b"
            alt="Logo"
          />
        </a>
        <div className={`navbar-links ${isOpen ? "active" : ""}`}>
          <div className="services-dropdown">
            <span className="nav-link">Services</span>
            <div className="dropdown-menu">
              <a href="/salesforce" className="dropdown-item">
                Salesforce Solutions
              </a>
              <a href="/custom-apps" className="dropdown-item">
                Custom App Development
              </a>
              <a href="/consulting" className="dropdown-item">
                Strategic Tech Consulting
              </a>
            </div>
          </div>
          <a href="/portfolio" className="nav-link">
            Portfolio
          </a>
          <a href="/about" className="nav-link">
            About
          </a>
          <a href="#contact" className="nav-link">
            Contact
          </a>
        </div>
        <button
          className="navbar-toggle"
          onClick={toggleNavbar}
          aria-label={isOpen ? "Close menu" : "Open menu"}
        >
          {isOpen ? <IoClose size={24} /> : <IoMenu size={24} />}
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
