import React from "react";
import { FaRocket, FaUsers, FaLightbulb, FaHandshake } from "react-icons/fa";
import styles from "../styles/salesforcePage.module.css";
import ContactPage from "./contact";
import { useEffect } from "react";

const SalesforcePage = () => {
  const handleSchedule = () => {
    window.open(
      "https://calendly.com/cooper-tintech/1-on-1-software-discovery-session-crafting-your-vision",
      "_blank"
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.salesforcePage}>
      <header className={styles.header}>
        <div className={styles.headerOverlay}></div>
        <div className={styles.headerContent}>
          <div className={styles.textContainer}>
            <h1>Take Your Business to the Next Level with Salesforce</h1>
            <p>
              Transform the way you connect with customers, streamline your
              operations, and grow your business - whether you're expanding
              through acquisition or preparing for future transitions.
            </p>
            <button onClick={handleSchedule} className={styles.ctaButton}>
              Book a Free Consultation
            </button>
          </div>
        </div>
      </header>

      <section className={styles.processSection}>
        <div className={styles.container}>
          <h2>We Make Salesforce Simple</h2>
          <p className={styles.sectionIntro}>
            We understand that Salesforce can feel overwhelming. Whether you're
            considering implementation for the first time, looking to optimize
            your existing setup, or need to integrate multiple business systems,
            we're here to guide you through a simple, proven process.
          </p>
          <div className={styles.processList}>
            <div className={styles.processCard}>
              <span className={styles.stepNumber}>1</span>
              <FaLightbulb className={styles.processIcon} />
              <h3>
                Understand Your <br />
                Needs
              </h3>
              <p>
                We start by learning about your business goals and challenges to
                ensure Salesforce truly serves your needs.
              </p>
            </div>
            <div className={styles.processCard}>
              <span className={styles.stepNumber}>2</span>
              <FaHandshake className={styles.processIcon} />
              <h3>Create Your Plan</h3>
              <p>
                Together, we'll develop a clear roadmap that fits your budget
                and timeline, customized to work the way your business does.
              </p>
            </div>
            <div className={styles.processCard}>
              <span className={styles.stepNumber}>3</span>
              <FaRocket className={styles.processIcon} />
              <h3>Setup & Configure</h3>
              <p>
                We handle all the technical details while keeping you informed
                every step of the way, ensuring a smooth implementation.
              </p>
            </div>
            <div className={styles.processCard}>
              <span className={styles.stepNumber}>4</span>
              <FaUsers className={styles.processIcon} />
              <h3>Train & Support</h3>
              <p>
                We ensure your entire team feels confident using Salesforce
                through hands-on training and ongoing support, leading to better
                adoption and results.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.services}>
        <div className={styles.container}>
          <h2>How We Can Help</h2>
          <div className={styles.servicesGrid}>
            <div className={styles.serviceCard}>
              <div className={styles.serviceHeader}>
                <FaRocket className={styles.serviceIcon} />
                <h3>New to Salesforce? We'll help you: </h3>
              </div>
              <ul>
                <li>Understand if Salesforce is right for your business</li>
                <li>Plan a smooth implementation that fits your budget</li>
                <li>Set up the essential features you need to succeed</li>
                <li>Train your team to use Salesforce effectively</li>
              </ul>
            </div>
            <div className={styles.serviceCard}>
              <div className={styles.serviceHeader}>
                <FaUsers className={styles.serviceIcon} />
                <h3>Already Using Salesforce? We can: </h3>
              </div>
              <ul>
                <li>Review and optimize your current setup</li>
                <li>Integrate systems after acquisitions</li>
                <li>Simplify complex processes</li>
                <li>Improve team adoption and effectiveness</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <ContactPage id="contact" />
    </div>
  );
};

export default SalesforcePage;
