import React from "react";
import { Link } from "react-router-dom";
import { FaSalesforce, FaCode, FaLaptopCode } from "react-icons/fa";
import styles from "../styles/services.module.css";

const ServicesSection = () => {
  const services = [
    {
      icon: <FaSalesforce className={styles.icon} />,
      title: "Salesforce Solutions",
      description:
        "Transform your business operations with tailored Salesforce implementations, integrations, and optimizations. Whether you're exploring Salesforce for the first time or looking to maximize its potential, we’re here to help.",
      color: "#00a1e0",
      route: "/salesforce",
    },
    {
      icon: <FaLaptopCode className={styles.icon} />,
      title: "Custom Application Development",
      description:
        "Build tailored software solutions that address your unique business challenges. From web applications to mobile solutions, we create scalable, secure, and user-friendly applications.",
      color: "#2e59a7",
      route: "/custom-apps",
    },
    {
      icon: <FaCode className={styles.icon} />,
      title: "Strategic Technology Consulting",
      description:
        "Navigate the technology landscape with confidence through roadmapping, architecture planning, and strategic guidance. Our Fractional CTO services align tech investments with business goals.",
      color: "#1e3a8a",
      route: "/consulting",
    },
  ];

  return (
    <section className={styles.servicesSection}>
      <div className={styles.container}>
        <h1 className={styles.title}>Services</h1>
        <p className={styles.subtitle}>
          Technology solutions to drive your business forward
        </p>

        <div className={styles.servicesGrid}>
          {services.map((service, index) => (
            <div
              key={index}
              className={styles.serviceCard}
              style={{ "--accent-color": service.color }}
            >
              <div className={styles.iconWrapper}>{service.icon}</div>
              <h3>{service.title}</h3>
              <p>{service.description}</p>
              <Link to={service.route} className={styles.learnMore}>
                Learn More
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
