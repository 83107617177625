import React from "react";
import {
  FaChartLine,
  FaLightbulb,
  FaRoute,
  FaSearch,
  FaHandshake,
  FaShieldAlt,
  FaProjectDiagram,
  FaCheck,
  FaCogs,
} from "react-icons/fa";
import styles from "../styles/consultingPage.module.css";
import ContactPage from "./contact";

const ConsultingPage = () => {
  const handleSchedule = () => {
    window.open(
      "https://calendly.com/cooper-tintech/1-on-1-software-discovery-session-crafting-your-vision",
      "_blank"
    );
  };

  return (
    <div className={styles.consultingPage}>
      <header className={styles.header}>
        <div className={styles.headerOverlay}></div>
        <div className={styles.headerContent}>
          <div className={styles.textContainer}>
            <h1>Tech Solutions for Growing Companies</h1>
            <p>
              Whether you're preparing to sell your business or integrating a
              new acquisition, we'll help you get your technology systems ready
            </p>
            <button onClick={handleSchedule} className={styles.primaryButton}>
              Schedule a Consultation
            </button>
          </div>
        </div>
      </header>

      <section className={styles.services}>
        <div className={styles.container}>
          <h2>Our Services for Growing Businesses</h2>
          <div className={styles.servicesGrid}>
            <div className={styles.serviceCard}>
              <FaRoute className={styles.serviceIcon} />
              <h3>Technology Planning</h3>
              <p>
                Get your systems ready for a successful business sale or
                smoothly integrate newly acquired operations.
              </p>
              <ul>
                <li>
                  <FaCheck /> System documentation & valuation
                </li>
                <li>
                  <FaCheck /> Technology inventory assessment
                </li>
                <li>
                  <FaCheck /> Integration planning
                </li>
                <li>
                  <FaCheck /> Cost analysis & budgeting
                </li>
              </ul>
            </div>
            <div className={styles.serviceCard}>
              <FaSearch className={styles.serviceIcon} />
              <h3>Operations Technology Review</h3>
              <p>
                Evaluate your current systems and identify improvements that
                will increase your company's value.
              </p>
              <ul>
                <li>
                  <FaCheck /> Equipment & software audit
                </li>
                <li>
                  <FaCheck /> Data security assessment
                </li>
                <li>
                  <FaCheck /> Efficiency evaluation
                </li>
                <li>
                  <FaCheck /> Growth opportunity analysis
                </li>
              </ul>
            </div>
            <div className={styles.serviceCard}>
              <FaLightbulb className={styles.serviceIcon} />
              <h3>System Modernization</h3>
              <p>
                Update your operations with practical technology that increases
                efficiency and company value.
              </p>
              <ul>
                <li>
                  <FaCheck /> Workflow automation
                </li>
                <li>
                  <FaCheck /> Staff training
                </li>
                <li>
                  <FaCheck /> System integration
                </li>
                <li>
                  <FaCheck /> Growth planning
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.approach}>
        <div className={styles.container}>
          <h2>Our Straightforward Approach</h2>
          <div className={styles.approachSteps}>
            <div className={styles.step}>
              <span className={styles.stepNumber}>1</span>
              <h3>Assessment</h3>
              <p>
                Review your current operations and understand your growth or
                sale goals
              </p>
            </div>
            <div className={styles.step}>
              <span className={styles.stepNumber}>2</span>
              <h3>Planning</h3>
              <p>
                Identify practical improvements that will increase efficiency
                and company value
              </p>
            </div>
            <div className={styles.step}>
              <span className={styles.stepNumber}>3</span>
              <h3>Action Plan</h3>
              <p>Develop a clear roadmap that works for your team and budget</p>
            </div>
            <div className={styles.step}>
              <span className={styles.stepNumber}>4</span>
              <h3>Support</h3>
              <p>
                Guide your team through changes and ensure smooth operations
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.expertise}>
        <div className={styles.container}>
          <h2>How We Help</h2>
          <div className={styles.expertiseGrid}>
            <div className={styles.expertiseCard}>
              <FaProjectDiagram className={styles.expertiseIcon} />
              <h3>System Integration</h3>
              <p>
                Combine multiple business systems efficiently after an
                acquisition or streamline operations for a sale.
              </p>
            </div>
            <div className={styles.expertiseCard}>
              <FaShieldAlt className={styles.expertiseIcon} />
              <h3>Data Protection</h3>
              <p>
                Secure your business data and customer information to protect
                your company's value and reputation.
              </p>
            </div>
            <div className={styles.expertiseCard}>
              <FaCogs className={styles.expertiseIcon} />
              <h3>Operations Improvement</h3>
              <p>
                Modernize your workflows to increase efficiency and make your
                business more attractive to buyers.
              </p>
            </div>
            <div className={styles.expertiseCard}>
              <FaHandshake className={styles.expertiseIcon} />
              <h3>Technology Planning</h3>
              <p>
                Develop practical technology strategies that support your
                business growth or sale goals.
              </p>
            </div>
          </div>
        </div>
      </section>

      <ContactPage id="contact" />
    </div>
  );
};

export default ConsultingPage;
